.controls-select-tree {
  position: absolute;
  width: 90%;
  height: 90%;
  top: 5%;
  left: 5%;
  padding: 10px;
  background-color: white;
  box-shadow: 0 0 1px 1px lightgray;

  input {
    width: 90% !important;
  }

  i.icon.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 2;
    font-size: 1.2em;
    color: gray;
  }

  i.icon.remove {
    cursor: pointer;
  }

  div.list {
    width: 100%;
    overflow-y: visible;
    overflow-x: hidden;
    height: 80%;
  }

  ul {
    list-style: none;
    display: block;
    width: 100%;
    margin: 20px 0 0 0;
    padding: 0;

    li {
      display: block;
      width: 100%;
      padding: 5px;
      &:hover {
        background-color: aliceblue;
        cursor: pointer;
      }

      &.level-2 {
        padding-left: 30px;
      }

      &.level-3 {
        padding-left: 60px;
      }

      &.level-4 {
        padding-left: 90px;
      }

      &.level-5 {
        padding-left: 120px;
      }

      &.level-6 {
        padding-left: 150px;
      }
    }
  }
}