.merge-confirm-left,
.merge-confirm-right {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      list-style: none;
      padding: 5px 0;
    }
  }
}

.merge-confirm-direction.left {
  p {
    margin: 16px 0 0 0;
  }
}

.merge-confirm-direction.right {
  p {
    margin: 0 0 16px 0;
  }
}