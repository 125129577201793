$selected-red: #a80101;

$margins: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 47, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100,
105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180, 185, 190, 195, 200,
205, 210, 215, 220, 225, 230, 235, 240, 245, 250, 255, 260, 265, 270, 275, 280, 285, 290, 295, 300);

@mixin generate-margin-bottom() {
  @each $value in $margins {
    .mb-#{$value} {
      margin-bottom: #{$value}px !important;
    }
  }
}

@mixin generate-margin-right() {
  @each $value in $margins {
    .mr-#{$value} {
      margin-right: #{$value}px !important;
    }
  }
}

@mixin generate-margin-top() {
  @each $value in $margins {
    .mt-#{$value} {
      margin-top: #{$value}px !important;
    }
  }
}

@mixin generate-margin-left() {
  @each $value in $margins {
    .ml-#{$value} {
      margin-left: #{$value}px !important;
    }
  }
}

@mixin generate-padding-top() {
  @each $value in $margins {
    .pt-#{$value} {
      padding-top: #{$value}px !important;
    }
  }
}

@mixin generate-padding-bottom() {
  @each $value in $margins {
    .pb-#{$value} {
      padding-bottom: #{$value}px !important;
    }
  }
}

@mixin generate-padding-left() {
  @each $value in $margins {
    .pl-#{$value} {
      padding-left: #{$value}px !important;
    }
  }
}

@mixin generate-padding-right() {
  @each $value in $margins {
    .pr-#{$value} {
      padding-right: #{$value}px !important;
    }
  }
}

@include generate-margin-bottom();
@include generate-margin-right();
@include generate-margin-top();
@include generate-margin-left();
@include generate-padding-bottom();
@include generate-padding-top();
@include generate-padding-left();
@include generate-padding-right();