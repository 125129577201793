.edit-filter-fields {
  overflow: hidden;
  position: relative;

  & > .left {
    float: left;
    width: 45% !important;
    position: relative;
  }
  & > .right {
    float: right;
    width: 45% !important;
    position: relative;
  }
  & > i {
    position: absolute;
    left: 48%;
    top: 5px;
  }

  .ui.label.bc {
    padding: 1px;
    cursor: pointer;
    position: absolute;
    right: -1px;
    top: 0;
    z-index: 1;
    &.blue {
      padding: 1px;
    }
  }
}