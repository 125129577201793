p.simple-inline-actions-row {
  margin: 15px 0;
  padding: 0;
  color: black;
  float: left;

  &.left {
    width: 530px;

    button {
      margin: 0 6px 0 0 !important;
    }
  }

  &.right {
    float: right;
    width: 380px;
    text-align: right;

    button {
      margin: 0 0 0 6px !important;
    }
  }

  button {
    margin-right: 6px !important;
  }

  input.new-item {
    border: 1px solid #DBDBDB;
    background-color: white;
    margin-right: 6px;
    outline: none;
    padding: 2px;
    height: 24px;
    vertical-align: middle;

    &:focus {
      border-color: #85B7D9;
    }
  }
}