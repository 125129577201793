.assign-subjects-list {
  margin-top: 30px;
}

.assign-message-text {
  margin-top: 20px;
}

.field.rules {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 2px;
      padding: 3px;

      &:hover {
        background-color: #f2f2f2;
        cursor: pointer;
      }
    }
  }
}

.context-modal {
  .filtered-count {
    font-size: 1.1em;
    color: black;
    font-weight: bold;

    &.large {
      font-size: 1.5em;
      color: $selected-red;
    }
  }
}