.ui.label.header-block-sort {
  background-color: transparent;
  border: none;
  margin-left: -10px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  color: grey;
  a {
    position: relative;
    padding-right: 20px;
    color: black;
    i {
      opacity: 0.5;
      position: absolute;
      right: -6px;
    }
  }
  &.active {
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    a {
      color: black;
      opacity: 1;
    }
  }
}