.menu-top {
  top: 0;
  position: fixed;
  z-index: 3;
  width: 100%;
  max-width: 1418px;
  height: 55px;
  background: white;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  &.show {
    position: relative;
  }

  &--logo {
    position: absolute;
    top: 6px;
    left: 20px;
    width: 170px;
    height: 44px;
    border-right: 1px solid #dcdcdc;
    background: url("/images/ctg_header_img.png") 0 -3px no-repeat;
  }

  &--title {
    position: absolute;
    left: 206px;
    top: 18px;
    font-size: 16px;
    font-weight: 700;
  }

  & > a {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 170px;
  }

  menu {
    margin: 0 0 0 190px;
    padding: 0;
    height: 100%;
    overflow: hidden;

    li {
      list-style: none;
      display: inline-block;
      text-transform: uppercase;
      height: 100%;
      font-size: 11px;
      font-weight: 700;

      a {
        display: block;
        height: 100%;
        width: 100%;
        padding: 18px 30px;

        border-bottom: 3px solid white;
        color: #888;

        &.active,
        &:hover {
          border-bottom: 3px solid $selected-red;
          color: black;
        }
      }

      &.in-progress a {
        color: #ddd;
        &.active,
        &:hover {
          border-bottom: 3px solid #ddd;
          color: #ddd;
        }
      }

      > ul {
        top: 50px;
        position: absolute;
        margin: 0;
        padding: 0;
        width: 150px;
        background-color: white;

        li {
          width: 100%;
          height: 40px;
          margin: 0;
          border-bottom: 1px solid #e2e2e2;

          a {
            border: 0;
            border-left: 3px solid transparent;
            display: block;
            height: 100%;
            width: 100%;
            padding: 8px 28px;

            &.active,
            &:hover {
              border: 0;
              border-left: 3px solid $selected-red;
              border-bottom: 0;
              color: black;
            }
          }

          &.in-progress a {
            color: #ddd;
            &.active,
            &:hover {
              border-left: 3px solid #ddd;
              border-bottom: 0;
              color: #ddd;
            }
          }
        }
      }
    }
  }

  &--title {

  }
}