.edit-form {
  .remove.icon {
    float: left;
    margin-top: 1px;
  }
}

.autocomplete-add-item {
  position: relative;
}

$placeholder-hide: transparent !important;
$placeholder-filled: black !important;

.autocomplete-one-item {
  position: relative;

  .shown-label-on-focus {
    position: absolute;
    top: 2px;
    opacity: 0.6;
    font-size: 11px;
    width: 80%;
    right: 0;
    z-index: 1;
    text-align: right;
    color: black;
    background-color: white;
  }

  :focus::-webkit-input-placeholder {
    color: $placeholder-hide
  }
  :focus::-moz-placeholder {
    color: $placeholder-hide
  }
  :focus:-moz-placeholder {
    color: $placeholder-hide
  }
  :focus:-ms-input-placeholder {
    color: $placeholder-hide
  }

  .filled {

    &::-webkit-input-placeholder {
      color: $placeholder-filled
    }
    &::-moz-placeholder {
      color: $placeholder-filled
    }
    &:-moz-placeholder {
      color: $placeholder-filled
    }
    &:-ms-input-placeholder {
      color: $placeholder-filled
    }

    &:focus::-webkit-input-placeholder {
      color: $placeholder-hide
    }
    &:focus::-moz-placeholder {
      color: $placeholder-hide
    }
    &:focus:-moz-placeholder {
      color: $placeholder-hide
    }
    &:focus:-ms-input-placeholder {
      color: $placeholder-hide
    }
  }

  .icon.button {
    height: 35px;
    width: 30px;
    min-width: 30px;
    text-align: center;

    > i.icon.remove {
      vertical-align: middle;
      width: 20px;
      margin-left: 4px !important;
      margin-top: -7px !important;
    }
  }
}

.ui.form {
  input[type='text'] {
    padding: 8px 1em;
  }
  select {
    padding: 7px 1em;
    -webkit-appearance: menulist-button;
    height: 34px;
    background-color: white;

    &:focus {
      outline: none;
    }
  }

  &.edit-block,
  .edit-block {
    background-color: white;
    padding: 0.5rem 0;
    margin: 0.5rem 0;
    border-radius: 3px;

    &.top-right-controls {
      padding-top: 1.5rem;
      position: relative;
    }

    .field {
      margin-bottom: 1em;
    }
    .column {
      padding: 0 10px 0 10px;
    }
    .ui.grid {
      margin: 0;
      padding: 0;
    }
    .ui.radio.checkbox {
      margin: 0;
      padding: 0;
      &.pt-10 {
        padding-top: 10px;;
      }
      &.pt-20 {
        padding-top: 20px;;
      }
      &.pt-30 {
        padding-top: 30px;
      }
    }

    &.grid-compact {
      margin-top: 1px;
      margin-bottom: 0;
    }

    &.with.actions {
      button {
        margin: 3px 0;
        min-width: 150px;
      }
    }
  }

  .edit-inner-block {
    margin: 0;
    padding: 0;
  }

  a.small.context {
    line-height: 14px;
    margin-bottom: -3px;
    font-size: 11px;
  }

  .no-scroll {
    overflow: hidden;
  }

  .column.column-compact {
    margin: 0;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .ui.grid > .clear-padding {
    padding: 0;
  }

  p.subtitle {
    color: gray;
    font-size: 0.9em;
  }
}

div[data-preferred="no-pref"] {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

.required-star {
  content: '*';
  margin-left: 2px;
  color: red;
  font-size: 10px;
  vertical-align: top;
}

.ui.form {
  div.required > label:after,
  label.required:after {
    @extend .required-star;
  }
}

h3.required:after {
  @extend .required-star
}

/** Errors */

.ui.form .field > label {
  font-weight: 600;
}

.ui.form .field > label.error {
  color: #E03997;
  font-size: 10px;
  line-height: 10px;
}

.ui.pointing.below.pink.basic.label {
  border: 0;
  font-weight: 600;
  padding: 0;

  ul {
    font-size: 10px;
    list-style: none;
    margin: 0;
    padding: 0 0 10px 0;
    li {
      list-style: none;
      .attr {
        color: gray;
        &:after {
          content: '] ';
        }
        &:before {
          content: '[';
        }
      }
    }
  }

  &:before {
    content: '';
    display: none;
  }
}

.ui.grid > .batch-model-errors {
  width: 100%;
  display: none;
  padding: 0;
  margin: 0;
  &.enabled {
    display: block;
  }
}

.ui.grid.form > .batch-model-errors {
  margin: 0 10px;
}

div.batch-model-errors[class*="enabled"] + div.batch-model-errors {
  margin-top: -20px !important;
}

.ui.grid.top-right-controls > .batch-model-errors {
  margin-top: -12px;
}

.ui.grid > .row.row-compact,
.row.row-compact {
  margin: 0;
  padding: 2px 0;
}
