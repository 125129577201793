.predicted-hist {
  height: 320px;

  span.estimate-line {
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    left: 20px;
    background-color: red;
  }

  span.background-colored {
    display: block;
    position: absolute;
    width: 30px;
    left: -10px;
  }

  .hist-item {
    position: relative;
    display: block;
    list-style: none;
    height: 14px;
    background-color: #2185D0;
    font-size: 12px;
    margin-bottom: 1px;
    padding: 0;
    margin-left: 20px;
    min-width: 0;
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.2);

    &.bi {
      background-color: #ffc98b;
    }
    &.below {
      background-color: #ffff83;
    }
    &.within {
      background-color: #c9ff9b;
    }
    &.above {
      background-color: #75ff8c;
    }

    span {
      position: absolute;
      font-family: "Courier New", monospace;
      text-align: right;

      &.price {
        text-align: left;
        left: -82px;
        height: 13px;
        top: 35px;
        width: 96px;
        transform: rotate(120deg);
      }

      &.point {
        left: -4px;
        height: 10px;
        top: -8px;
        width: 10px;
        transform: rotate(90deg);
      }

      &.count {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: right;
        color: white;
        top: -2px;
        font-size: 11px;
      }
    }
  }

  .price-hist {
    transform: rotate(-90deg);
    height: 600px;
    width: 300px;
    position: relative;
    display: block;
    padding: 0;
    left: 170px;
    top: -130px;
  }
}

.probability-indicator {
  position: relative;
  float: left;
  display: block;
  height: 20px;
  min-width: 5%;
  text-align: center;

  &.bi {
    background-color: #ffc98b;
  }

  &.below-low-estimate {
    background-color: #ffff83;
  }

  &.within-estimates {
    background-color: #c9ff9b;
  }

  &.above-high-estimate {
    background-color: #75ff8c;
  }
}
