.list-search-header {
  z-index: 2;
  height: 110px;
  width: 100%;
  max-width: 1418px;
  position: fixed;
  top: 55px;
  padding: 20px 30px;
  color: #9b9b9b;
  font-size: 14px;
  background-color: #f2f2f2;

  .icon.search {
    position: absolute;
    top: 31px;
    left: 44px;
  }

  > input {
    height: 40px;
    width: 100%;
    border: 1px solid #dbdbdb;
    padding: 4px 200px 4px 40px;
    color: black;
  }

  &.subtitle {
    .icon.search {
      top: 38px;
    }

    > input {
      height: 36px;
      margin-top: 10px;
      margin-bottom: -6px;
    }

    .subtitle {
      position: absolute;
      top: 6px;
      display: block;
      color: black;
    }

    .subtitle-right {
      position: absolute;
      top: 5px;
      width: 200px;
      right: 0;
      text-align: right;
      padding-right: 30px;

      a {
        text-decoration: none;
        color: gray;

        &.selected {
          color: #4183c4;
        }
      }
    }
  }

  .headers-box-modifications {
    width: 55px;
    position: absolute;
    right: 57px;
    text-align: right;

    @media (max-width: 1059px) {
      display: none;
    }
  }

  .search-summary {
    position: relative;
    margin-top: 16px;

    & > .results-quantity {
      float: left;
      display: block;
      color: black;
      width: 230px;
      font-size: 0.9em;
    }

    .headers-box {
      position: absolute;
      left: 365px;
      float: left;
      list-style: none;
      display: flex;
      padding: 0;
      margin: 0;
      height: 22px;
      right: -20px;
      overflow: hidden;

      .price {
        width: 125px;
      }

      .department {
        display: inline-flex;
        width: auto;
        flex: 1;
        padding-left: 0;

        .float-label {
          display: inline-block;
          margin: 0 auto;
          width: 100px;
          padding-left: 15px;
        }
      }

      li {
        display: inline-block;
      }

      &.without-checkbox {
        left: 320px;
      }
    }

    .sort-box {
      position: absolute;
      left: 220px;
      width: 130px;
      color: gray;
      cursor: pointer;

      &.active {
        color: $selected-red;
      }

      ul.sort-by-list {
        color: black;
        display: none;
        margin: 10px 0 0 0;
        padding: 0;
        list-style: none;
        background-color: white;
        box-shadow: 1px 1px 2px grey;
        width: 200px;

        li {
          padding: 8px;
          cursor: pointer;
          border-left: 2px solid white;

          &:hover {
            background-color: lightgray;
            border-left: 2px solid lightgray;
          }

          &.active {
            border-left: 2px solid $selected-red;
          }
        }
      }

      &:hover {
        color: black;

        &.active {
          color: $selected-red;
        }

        ul.sort-by-list {
          display: block;
        }
      }
      //
      //
      //li {
      //  display: inline-block;
      //  margin-right: 0;
      //  padding-left: 10px;
      //  border-left: 1px solid transparent;
      //  border-right: 1px solid transparent;
      //  border-radius: 3px;
      //
      //  &.sort-by-list {
      //    position: absolute;
      //    left: -50px;
      //    font-size: 0.9em;
      //
      //    ul {
      //      display: none;
      //      margin: 10px 0 0 0;
      //      padding: 0;
      //      list-style: none;
      //      background-color: white;
      //      box-shadow: 1px 1px 2px grey;
      //
      //      li {
      //        padding: 8px;
      //
      //        &:hover {
      //          background-color: lightgray;
      //        }
      //      }
      //    }
      //  }
      //
      //  &:hover {
      //    &.sort-by-list {
      //      color: black;
      //      cursor: pointer;
      //
      //      ul {
      //        display: block;
      //      }
      //    }
      //  }
      //
      //  &[aria-current] {
      //    border-left: 1px solid gray;
      //    border-right: 1px solid gray;
      //    color: black;
      //
      //    .fa {
      //      opacity: 1;
      //    }
      //  }
      //
      //  .fa {
      //    opacity: 0;
      //    width: 16px;
      //    margin-left: 2px;
      //  }
      //
      //  &.fix-right {
      //    position: absolute;
      //    right: 20px;
      //  }
      //}
    }
  }

  &.margin-context {
    top: 85px;
  }
}
