.table-block {
  .title,
  .lots,
  .comment,
  .date,
  .prices,
  .bio {
    padding-left: 20px;
  }

  .page-delimiter {
    opacity: 0;
    height: 0;
    width: 100%;
    position: relative;
    z-index: 1;

    > div {
      position: absolute;
      top: -10px;
      left: -5px;
      font-size: 10px;
      color: grey;
    }
  }

  .page-number {
    position: absolute;
    top: -4px;
    left: 0;
    color: grey;
    font-size: 10px;
  }

  & > .row {
    overflow: hidden;
    padding: 4px;
    background-color: white;
    border-bottom: 1px solid #f2f2f2;
    height: 78px;
    width: 100%;
    clear: both;

    > a.view {
      display: block;
      width: calc(100% - 40px);
      height: 100%;
      position: absolute;
      top: 0;
      cursor: pointer;
      z-index: 1;
      left: 40px;

      .link-action-notice {
        position: absolute;
        background: white;
        opacity: 0.4;
        display: none;
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 50px;
        padding-top: 30px;
        font-weight: bold;
      }

      &:hover {
        .link-action-notice {
          display: block;
        }
      }
    }

    & > .column {
      height: 100%;
      display: block;
      position: relative;
      float: left;

      &.center {
        text-align: center;
      }

      &.checkbox {
        padding: 24px 8px;
        width: 40px;
      }

      &.image {
        width: 70px;

        .unbreakable-image {
          width: 100%;
          height: 100%;
          box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);

          img {
            height: auto;
            width: 100%;
          }
        }
      }

      &.title,
      &.lots,
      &.comment,
      &.date,
      &.prices,
      &.bio {
        width: 150px;
        display: table;
        overflow: hidden;

        p {
          display: table-cell;
          vertical-align: middle;
          margin: 0;
          padding: 0;
          max-width: 130px;

          br {
            content: normal;
          }
        }

        &.short {
          width: 100px;
          span {
            max-width: 80px !important;
          }
        }

        &.middle {
          width: 125px;
          span {
            max-width: 105px !important;
          }
        }

        &.mini {
          width: 60px;
          span {
            max-width: 40px !important;
          }
        }

        &.short-mini {
          width: 30px;
        }

        &.nowrap {
          white-space: nowrap;

          &:after {
            content: '';
            width: 10%;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0),
              #fff 60%
            );
          }

          span {
            display: block;
            max-width: 130px;
            overflow: hidden;
          }
        }

        &.long {
          width: 200px;
          span {
            max-width: 180px;
          }
          &.too {
            width: 250px;
            span {
              max-width: 220px;
            }
            &.much {
              width: 300px;
              span {
                max-width: 280px;
              }
            }
          }
        }
      }

      &.level {
        padding-left: 20px;
        display: table;

        span {
          color: lightgray;
          display: table-cell;
          vertical-align: middle;
        }

        &.l1 {
          width: 0;
        }
        &.l2 {
          width: 120px;
        }
        &.l3 {
          width: 170px;
        }
        &.l4 {
          width: 220px;
        }
        &.l5 {
          width: 270px;
        }
        &.l6 {
          width: 320px;
        }
        &.l7 {
          width: 370px;
        }
        &.l8 {
          width: 420px;
        }
        &.l9 {
          width: 470px;
        }
        &.l10 {
          width: 520px;
        }
      }

      &.title {
        font-weight: bold;
        height: 70px;

        &:after {
          content: '';
          width: 98%;
          display: block;
          position: absolute;
          top: 50px;
          left: 2%;
          height: 30px;
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            #fff 60%
          );
        }

        p {
          margin-top: 3px;
        }
      }

      &.lots {
        width: 90px;
        padding-left: 5px;
      }

      &.comment {
        background-color: white;
        width: 400px;
        position: absolute;
        top: 0;
        display: none;
        z-index: 1;
        right: 40px;
        padding: 10px;

        &.visible {
          display: block;
        }

        &.short {
          width: 100px;
        }

        &.middle {
          width: 200px;
        }
      }

      &.prices {
        // width: 200px;
      }

      &.date {
        width: 70px;
        position: absolute;
        right: 20px;
        background: white;

        span {
          margin-top: 7px;
          min-width: 56px;
          text-align: center;

          &.grey {
            color: #b3b3b3 !important;
            border-color: #b3b3b3 !important;
          }

          &.lots-count {
            position: relative;
            top: 3px;
            font-size: 12px;
          }
        }
      }

      &.simple-inline-actions {
        @extend .no-select;
        position: absolute;
        top: 0;
        right: 10px;
        width: 410px;
        background-color: inherit;
        padding-left: 50px;
        display: none;
      }
    }

    &:hover > .column.simple-inline-actions {
      display: block;
    }

    .ui.icon.close,
    .ui.icon.undo,
    .ui.icon.random,
    .ui.icon.pencil {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      z-index: 1;
      color: grey;
    }

    .ui.icon.pencil {
      display: none;
    }

    & > .inline-actions {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 50px;
      z-index: 1;

      .inline-actions-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        padding-top: 5px;
        padding-right: 20px;
        display: none;
        background-color: aliceblue;
      }

      &.edit-mode {
        width: 100%;
        .inline-actions-wrapper {
          display: block;
        }
      }

      .field {
        background-color: aliceblue;
        padding: 5px;
        width: 200px;
        float: right;
        clear: none;
        margin: 0;
        display: block;
        min-height: 100%;

        &.large {
          width: 500px;
          text-align: right;

          input {
            max-width: 200px;
          }
        }

        .ui.button {
          border: 0;
          box-shadow: none !important;
        }
      }

      span.title {
        display: block;
        width: 100px;
        float: left;
        font-weight: bold;
        padding: 10px;
      }
    }

    &.merged,
    &.deleted {
      opacity: 0.2;
    }

    &.headline {
      & > .column {
        color: grey !important;
        font-weight: bold !important;
      }
    }

    &.selected {
      color: $selected-red;
      border-right: 3px solid $selected-red;
    }

    .ui.icon.angle.right {
      width: 10px;
      margin-left: 5px;
    }

    .circle-counter {
      display: inline-block;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      text-align: center;
      font-size: 0.8em;
      font-weight: 500;
      letter-spacing: 0.02em;
      background-color: #f2f2f2;
      color: #9b9b9b;
    }
  }

  .hoverable:hover {
    .column.title:after {
      background: linear-gradient(
        to bottom,
        rgba(204, 226, 255, 0),
        #f1f7ff 60%
      );
    }

    .inline-actions .field {
      background-color: rgba(240, 248, 255, 1);
    }

    .column.nowrap:after {
      background: linear-gradient(
        to right,
        rgba(204, 226, 255, 0),
        #f1f7ff 60%
      );
    }

    .ui.icon.ban,
    .ui.icon.pencil {
      display: block;
    }

    .column.comment {
      display: block;
      background-color: aliceblue;
    }

    .column.date {
      background-color: aliceblue;
    }
  }

  .comparable {
    background: #e7ffec !important;

    .column.title:after {
      background: linear-gradient(
        to bottom,
        rgba(241, 247, 255, 0),
        #e7ffec 60%
      ) !important;
    }

    .column.nowrap:after {
      background: linear-gradient(
        to right,
        rgba(241, 247, 255, 0),
        #e7ffec 60%
      ) !important;
    }

    .column.date {
      background: linear-gradient(
        to bottom,
        rgba(204, 226, 255, 0),
        #e7ffec 60%
      );
    }

    &:hover {
      background: #cefff5 !important;

      .column.title:after {
        background: linear-gradient(
          to bottom,
          rgba(241, 247, 255, 0),
          #cefff5 60%
        ) !important;
      }

      .column.nowrap:after {
        background: linear-gradient(
          to right,
          rgba(241, 247, 255, 0),
          #cefff5 60%
        ) !important;
      }

      .column.date {
        background: linear-gradient(
          to bottom,
          rgba(204, 226, 255, 0),
          #cefff5 60%
        );
      }
    }
  }

  &.lots {
    & > .row {
      height: 100px;
      display: flex;

      & > .column {
        &.title {
          height: 90px;

          &:after {
            top: 70px;
          }
        }
      }
    }
  }
}

.table-block.lots {
  .row .column {
    padding-left: 15px;

    &.department {
      display: inline-flex;
      flex: 1 100px;
      align-items: center;

      .float-content {
        display: inline-block;
        margin: 0 auto;
        padding-left: 15px;
      }
    }
  }
}

.header-block {
  label {
    font-weight: 700;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
    vertical-align: middle;
    line-height: 26px;
  }

  .ui.checkbox.bulk-checkbox {
    width: 30px;
    float: left;
    label:before {
      top: 4px;
    }
  }
}

main.list-wrap-results {
  position: relative;
  margin-top: 170px;
  z-index: 0;

  .list-result-quantity {
    position: absolute;
    font-size: 11px;
    color: darkgrey;
    bottom: 0;
    text-align: right;
    right: 0;
    width: 200px;
    z-index: -1;
  }

  aside.list-wrap-results-filter-block {
    width: 250px;
    position: fixed;
    float: left;
    padding: 10px;
    overflow: auto;
    overflow-x: hidden;
    height: calc(100% - 200px);

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-border-radius: 10px;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: lightgray;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: lightgray;
    }

    .list-bulk-operations.disabled {
      label,
      * {
        color: lightgrey;
      }
      a {
        color: #4183c4;
      }
    }
  }

  article.list-wrap-results-list-block {
    width: calc(100% - 250px);
    float: right;
    padding: 10px;
    border-left: 1px solid #e9e9e9;
    overflow: hidden;
  }

  &.margin-context {
    margin-top: 205px;
  }
}
