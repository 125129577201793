table.user-reviews {
  input[type="checkbox"] {
    vertical-align: middle !important;
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  tr.checked {
    background-color: #eeeeee;
  }
}