i.icon.bring-to-top {
  display: none;
  font-size: 24px;
  position: absolute;
  background-color: #e8e8e8;
  border-radius: 15px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding-top: 3px;

  &.active {
    display: block;
  }
}