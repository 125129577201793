.list-right-block-header {
  margin-bottom: 20px;
  position: relative;

  span {
    font-weight: bold;
    font-size: 12px;
  }

  span.informer {
    position: absolute;
    display: block;
    text-align: right;
    right: 0;
    top: 0;
    font-weight: normal;
  }

  a {
    font-size: 12px;
    float: right;
    cursor: pointer;
    display: block;
    line-height: 15px;
    margin-top: 3px;
    margin-left: 10px;
  }

  &.disabled {
    opacity: 0.5;

    span.informer {
      color: black;
    }
  }
}

.bulk-operations-informer {
  font-size: 12px;
  position: relative;
  text-align: left;
  top: -24px;
  right: 0;

  &.left {

    a {
      font-size: 12px;
      display: inline;
      margin: 0 0 0 5px;
      padding: 0 2px;

      &.active {
        color: black;
        border-bottom: 1px solid $selected-red;
        background-color: #e2e2e2;
      }

      &.icon {
        margin: 0;
      }
    }
  }
}

.list-set-filters {

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      width: 100%;
      display: block;
      padding: 3px 3px 3px 6px;
      color: grey;
      border-left: 2px solid white;

      &:hover {
        cursor: pointer;
        background-color: #e2e2e2;
      }

      &.selected {
        color: black;
        border-left: 2px solid $selected-red;
      }
    }
  }

  ul.tree {
    margin-left: 10px;

    li {
      position: relative;
      height: 24px;
      white-space: nowrap;
      border-left: 0;

      &.sub {
        margin-left: 20px;
      }

      &.selected {
        border-left: 0;
      }

      .icon.expand {
        position: absolute;
        left: -15px;
      }
    }
  }

  .hide-end {
    display: block;
    position: absolute;
    top: 0;
    right: -10px;
    width: 50px;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
  }

  .field {
    position: relative;

    &.comparable {
      background-color: #e7ffec;
      padding: 5px;
    }
  }

  .ui.checkbox label, .ui.checkbox + label {
    font-size: .9em;
  }

  .ui.popup.calendar {
    font-size: 0.9em;
  }

  .piled.segments {
    padding: 8px;
  }

  .ui.grid.form.edit-block {
    &> div {
      padding-right: 0;
    }

    .checkbox-left {
      width: 48%;
      float: left;

      &-1 {
        float: left;
        width: 25%;

        &.calculated-total {
          width: 30%;
        }
      }

      &-2 {
        float: left;
        width: 30%;

        &.calculated-total {
          width: 40%;
        }
      }

      &-2 {
        &.calculated-total {
          width: 30%;
        }
      }
    }
  }
}
