.ui.button,
.ui.button.loading {
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;

  padding: 6px 10px 5px;
  border-bottom: 2px solid #ab4a4a;
  -webkit-font-smoothing: subpixel-antialiased;
  font-weight: 300;
  text-shadow: none;
  height: 33px;
  outline: 0;
  background: #D65D5D none;

  color: #fff;
  box-shadow: none;

  min-width: 70px;

  &:hover,
  &:active,
  &.active {
    background-color: #900;
    color: #fff;
    border-color: #000;
    transition: background-color .2s linear, border-color .2s linear, color .2s linear;
    box-shadow: none;
  }

  &.basic,
  &.basic.button,
  &.basic.loading {
    color: #898989;
    border-bottom: 2px solid #dbdbdb;
    background-color: #f2f2f2 !important;
    box-shadow: none;
    font-weight: 300;

    &:hover, &:active, &.active {
      background-color: #dbdbdb !important;
      border-color: #a4a4a4;
      color: #900 !important;
    }
  }

  &.mini {
    height: 24px;
    padding: 4px 5px;
    font-size: 12px;
  }
}

.ui.icon.buttons .button,
.ui.icon.button {
  padding: inherit;
}

.ui.icon.button.purple.confirm {
  min-width: 50px;
  padding: 0;
  border: 0;
  height: inherit;

  i {
    margin: 5px auto;
    line-height: normal;
  }
}

.field.assign {
  .ui.button.basic.button {
    background-color: white !important;
    border-bottom: none;
    width: 32%;
    min-width: 32%;
    color: black !important;
    border-color: black;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset !important;
    margin: 0;
    font-size: 11px;

    &.delete {
      margin-right: 0;
      float: right;
    }

    &:hover {
      background-color: #dbdbdb !important;
      color: black !important;
    }
  }

  .ui.button.basic.green.button {
    &:hover {
      color: green !important;
      background-color: #d2f2d0 !important;
    }
  }

  .ui.button.basic.red.button {
    &:hover {
      color: red !important;
      background-color: #f2d6d7 !important;
    }
  }

  .full-width {
    width: 100% !important;
  }

  .ui.buttons {
    .delete-confirm {
      width: 100px;
    }
    .delete-cancel {
      width: 70px !important;
    }
  }
}

