.edit-filter-month-field {

  label + div {
    text-align: center;
    overflow: hidden;
  }

  input {
    width: 20% !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
    text-align: center;

    &.left {
      float: left;
    }
    &.right {
      float: right;
    }
  }

  input.left + input.left {
    margin-left: 5px;
  }

  input.right + input.right {
    margin-right: 5px;
  }

  span.ndash {
    line-height: 30px;
  }
}