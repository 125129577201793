.flash-message {
  position: fixed !important;
  z-index: 9999999;
  top: 30px;
  width: 400px;
  left: calc(50% - 200px);

  &.disabled {
    display: none;
  }
}