.search-and-set-creator {
  .select-mode {
    display: block;
    position: absolute;
    right: 0;
    z-index: 1;
    width: 20px;
    height: 30px;
    cursor: pointer;
    text-align: center;
    line-height: 17px;

    br {
      height: 0;
    }

    i.ui.icon {
      opacity: 0.3;
      &.active {
        opacity: 1;
      }
      &.building {
        font-size: 0.99em;
      }
    }
  }

  .right-field {
    padding-right: 13px;
  }
}