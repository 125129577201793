$shadow-color-hard: rgba(0, 0, 0, .4);
$shadow-color-lite: rgba(0, 0, 0, .2);
$hover-color: #E5E5E5;

.header-panel {
  position: fixed;
  height: 80px;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 10;
  background-color: white;

  & + .application-body {
    margin-top: 80px;
  }

  .header-panel-main,
  .header-panel-sub {
    box-shadow: 0 2px 2px 0 $shadow-color-lite;
    width: 100%;
  }

  .header-panel-main {
    height: 40px;

    .user,
    .plus,
    .shortcuts {
      float: right;
      position: relative;
      width: 160px;
      height: 100%;
      border-left: 1px solid lightgrey;

      & > a {
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
        padding-top: 10px;
        &:hover {
          background-color: $hover-color;
        }
      }

      .items {
        position: absolute;
        display: none;
        width: 200px;
        right: 0;
        top: 40px;
        background-color: white;
        z-index: 11;
        box-shadow: 0 2px 2px $shadow-color-hard;

        ul {
          padding: 1px 0;
          margin: 0;
          width: 100%;
          list-style: none;
          background-color: lightgrey;

          li {
            display: block;
            width: 100%;
            list-style: none;
            margin-top: 1px;
            background-color: white;

            &:hover {
              background-color: $hover-color;
            }

            a {
              padding: 5px;
              display: block;
              width: 100%;
              height: 100%;
              font-size: 13px;

              label {
                display: block;
                float: right;
                font-size: 12px;
                cursor: pointer;
                color: gray;
              }
            }

            &.delimiter {
              background-color: gray;
              a {
                cursor: default;
                color: white;
                font-weight: 600;
                font-size: 11px;
              }
            }
          }
        }
      }

      &:hover .items {
        display: block;
      }
    }

    .plus {
      width: 40px;
    }

    .user {
      border-left: none;
      border-right: 1px solid lightgrey;
      width: 40px;
      float: left;

      .items {
        right: inherit;
        left: 0;
      }
    }

    .shortcuts .items {
      width: 250px;
    }

    .top-item {
      display: inline;
      float: left;
      height: 100%;
      border-right: 1px solid lightgrey;
      &:hover {
        background-color: $hover-color;
      }
      a {
        display: block;
        height: 100%;
        padding: 10px;
      }
    }
  }

  a.active {
    color: black;
  }

  a.inactive {
    opacity: 0.4;
  }

  .header-panel-sub {
    background-color: #f6f6f6;
    position: absolute;
    z-index: -1;

    div.breadcrumbs {
      height: 40px;
      padding: 10px 0 5px 20px;

      ul.breadcrumbs {
        margin: 0;
        padding: 0;
        li {
          position: relative;
          float: left;
          list-style: none;
          font-size: 12px;
          font-weight: 400;
          margin: 0 30px 0 0;
          &:after {
            content: '>';
            position: absolute;
            top: 0;
            right: -20px;
          }
          &:last-child:after {
            content: '';
            margin: 0;
            display: none;
          }
          &.current > a {
            color: gray;
            cursor: default;
          }
        }
      }
    }
  }
}