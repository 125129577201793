@import "components/comparables/collectrium-estimate-chart/styles";
@import "components/comparables/custom-estimate/styles";

.comparables-valuations {
  width: 340px;
  display: inline-block;
  float: left;
  color: black;
  font-size: 0.9em;

  p {
    margin: 0;
    padding: 0;
    font-weight: bold;

    label {
      font-weight: normal;
      display: inline-block;
      width: 260px;
    }
  }
}

.list-search-header.comparables {
  .search-summary {
    margin-top: 8px;
  }

  .results-quantity {
    font-size: 1em;
  }

  .headers-box {
    z-index: 1;
    left: 325px;
    right: 50px;
  }

}

.entities-entity-header.comparables {
  .entities-entity-header--footer {
    a.all-object-link {
      left: 250px;
      right: inherit;
    }
  }
}

.comparables-pagination {
  float: right;
  width: 450px;
  text-align: right;

  a {
    display: inline-block;
    min-width: 40px;
    padding: 4px;
    text-align: center;
    font-size: 12px;
    margin-left: 2px;

    &:hover {
      background-color: aliceblue;
    }

    &.active {
      background-color: #e2e2e2;
      &:hover {
        background-color: #e2e2e2;
      }
    }
  }
}

.comparables-chart-form {
  position: relative;
  width: 450px;
  padding-left: 30px;
}