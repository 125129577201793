.input-limit-indicator {
  position: absolute;
  right: 8px;
  margin-top: -14px;
  padding: 2px 4px;
  font-size: 9px;
  color: grey;
  line-height: 10px;
  display: none;

  &.active {
    display: block;
  }
}

.right-stick {
  .input-limit-indicator {
    right: 0;
  }
}