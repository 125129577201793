.cdata-slider {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 50px;
  user-select: none;
  cursor: default;

  svg.slider {
    height: 30px;
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    left: 0;
  }

  .values {
    .left {
      display: block;
      float: left;
      width: 48%;
      &.grey {
        color: #d2d2d2;
      }
    }

    .right {
      display: block;
      float: right;
      width: 48%;
      text-align: right;
      padding-right: 10px;
      &.grey {
        color: #d2d2d2;
      }
    }
  }
}