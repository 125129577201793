.upload-container {
  width: 100%;
  height: 300px;
  padding: 6px;
  box-shadow: 1px 1px 3px;
  border-radius: 3px;

  .pl-uploader {
    width: 100%;
    height: 55%;
    background-color: inherit;

    .dropzone {
      width: 100%;
      height: 100%;
      background-color: inherit;
    }
  }

  .fetch-url {
    height: 30%;
    width: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border-radius: 3px;
  }
}