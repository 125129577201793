.summary-info-comments {
  .value {
    position: relative;

    .deletion-confirm {
      position: absolute;
      top: 0;
      right: 0;
      width: 200px;
      height: 120px;
      text-align: right;
      padding: 3px;
    }
  }
}

.html-editor {
  width: 100%;
  height: 300px;

  .pell-button {
    &:hover {
      background-color: #f7f7f7;
    }
    &-selected {
      background-color: #eaeaea;
      &:hover {
        background-color: #e1e1e1;
      }
    }
  }
}