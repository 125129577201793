.organizations-organization-header,
.people-person-header,
.entities-entity-header,
.lots-lot-header,
.sales-sale-header,
.show-page-header {
  position: relative;
  width: 100%;
  height: 310px;
  max-width: 1418px;
  z-index: 1;

  &.small {
    height: 280px;
  }

  &--link-to-overview {
    position: absolute;
    top: 34px;
    left: 136px;
    font-size: 12px;

    a {
      color: lightgrey;
      .icon {
        font-size: 0.9em;
      }
    }
  }

  &--image {
    position: absolute;
    overflow: hidden;
    top: 77px;
    left: 139px;
    height: 230px;
    width: 230px;
    z-index: 1;
    transition: all .2s;

    i.pencil {
      display: none;
      opacity: 0.5;
      position: absolute;
      bottom: 0;
      right: 0;
      cursor: pointer;
      width: 30px;
      height: 30px;
      padding: 5px;
      border-radius: 15px;
      font-size: 1.2em;
      color: black;
      background-color: white;
      &:hover {
        display: block;
        opacity: 1;
      }
    }

    &:hover i.pencil {
      display: block;
    }

    .unbreakable-image {
      img {
        height: auto;
        width: 100%;
      }
    }
  }

  &.small &--image {
    overflow: visible;
    top: 170px;
    height: auto;
    width: 100px;

    .zoom {
      display: none;
    }

    img {
      margin: 0 !important;
    }
  }

  &--item-head {
    position: absolute;
    color: white;
    top: 74px;
    left: 391px;
    max-width: 600px;
    transition: all .2s linear;

    p {
      line-height: 20px;
      margin: 0 0 1px 0;
      font-weight: 100;
    }

    .artist {
      text-transform: uppercase;
      font-weight: bold;
    }

    .title {
      font-style: italic;
    }

    .alert {
      color: yellow;
      font-weight: bold;
      text-decoration: none;
    }

    .material {

    }

    .size {

    }
  }

  &.middle &--item-head,
  &.small &--item-head {
    top: 170px;
    p {
      display: inline-flex;
      line-height: 18px;
      &:after {
        content: " | ";
        font-style: italic;
        margin: 0 20px;
        color: gray;
      }
      &:last-child:after {
        content: "";
      }
    }
  }

  &.small &--item-head {
    top: 185px;
    left: 255px;
  }

  &--bgr {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 230px;
    background: url("/images/no-image.png") no-repeat center;
    background-size: cover;
    transition: all .2s linear;

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0.8;
      background-color: black;
    }
  }

  &--footer {
    position: absolute;
    width: calc(100% - 480px);
    left: 139px;
    height: 75px;
    background-color: white;
    bottom: 5px;
    z-index: 0;
    box-shadow: 0 5px 5px 0 white;
    min-width: 600px;
  }

  &.small &--footer {
    height: 45px;
    .bring-to-top {
      display: block;
      top: 9px;
      left: 220px;
    }
  }

  .bring-to-top {
    display: none;
  }

  .prev-next-links {
    margin-left: 70px;
    a {
      margin-left: 10px;
      &:hover {
        color: gray;
      }
      &.disable {
        display: none;
      }
    }
    .icon.left {
      margin-right: 0 !important;
    }
    .icon.right {
      margin-left: 0 !important;
    }
  }

  .all-object-link {
    display: block;
    position: absolute;
    top: 30px;
    right: 30px;

    &:nth-child(2) {
      top: 54px;
    }
  }

  &--prev-next-editor {
    height: 100px;
    background-color: white;
    box-shadow: 0 0 1px 0 gray;
    width: 350px;
    position: absolute;
    bottom: 0;
    right: 360px;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      text-align: center;
      padding-top: 35px;
    }

    div.item {
      position: absolute;
      width: 46%;
      height: 100%;
      margin: 1%;
      overflow: hidden;

      a {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        left: 0;
        z-index: 1;

        .icon {
          display: none;
          opacity: 0.8;
          font-size: 60px;
          position: relative;
          top: 30px;
        }

        &:hover {
          .icon {
            display: block;

          }
        }
      }

      p {
        font-size: 0.9em;
        line-height: 12px;
        margin-left: 90px;
      }

      .image {
        position: relative;
        float: left;
        width: 80px;
        height: 80px;
        margin-right: 4px;
        box-shadow: 0 0 1px 0 lightgray;

        .unbreakable-image {
          position: absolute;
        }

        .unbreakable-image, img {
          max-width: 80px;
          max-height: 80px;
          text-align: center;

          .img {
            text-align: center;
          }
        }
      }

      &.prev {
        left: 1%;
        a {
          .icon {
            float: left;
          }
        }
      }

      &.next {
        right: 1%;
        a {
          .icon {
            float: right;
          }
        }
      }
    }

    .close {
      width: 30px;
      height: 30px;
      position: absolute;
      top: -5px;
      right: -7px;
      cursor: pointer;

      i {
        color: white;
        font-size: 20px;
        padding: 0;
        margin: 0;
        &::before {
          text-shadow: 0 1px 1px black;
        }
      }
    }
  }

  &.small {
    .all-object-link {
      top: 16px;
      &:nth-child(2) {
        top: 40px;
      }
    }
  }

  &.little &--bgr,
  &.little {
    height: 70px;
  }

  &.little &--item-head {
    top: 16px;
    left: 444px;
  }

  &.little &--link-to-overview {
    top: 16px;
  }
}