.manage-sale-context {
  overflow: hidden;
  position: fixed;
  height: 30px;
  background-color: #e8f5ff;
  top: 55px;
  width: 100%;
  max-width: 1418px;
  padding: 5px 30px 5px 30px;
  z-index: 2;

  p {
    overflow: hidden;
    display: inline-flex;
    padding: 0;
    white-space: nowrap;
    margin-right: 20px;
  }

  .add-lot {
    position: absolute;
    right: 30px;
    width: 50px;
    top: 4px;
  }

  &.margin-add-btn {
    .add-lot {
      right: 100px;
    }
  }

  &.mode-relative {
    position: relative;
    top: 0;
  }
}