body,
html,
svg,
input,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Proxima Nova', sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  height: auto;
}

body > .ember-view {
  position: relative;
}

.application-body {
  position: relative;
  max-width: 1418px;
  min-width: 1100px;
  width: 100%;
  margin: 0 auto;

  &.show {
    background: url('/images/aside-bgr.gif') right repeat-y;
  }
}

a {
  text-decoration: none;
}

@font-face {
  font-family: Proxima Nova;
  src: url(/fonts/ProximaNova-Reg.otf);
}

@font-face {
  font-family: Proxima Nova;
  font-weight: bold;
  src: url(/fonts/ProximaNova-Bold.otf);
}

@font-face {
  font-family: Proxima Nova;
  font-weight: 100;
  src: url(/fonts/ProximaNova-Light.otf);
}

@font-face {
  font-family: Proxima Nova;
  font-weight: 300;
  src: url(/fonts/ProximaNova-Light.otf);
}

@font-face {
  font-family: 'Atlas Grotesk';
  font-weight: 500;
  src: url('/fonts/AtlasGrotesk-Medium-Web.woff2') format('woff2'),
    url('/fonts/AtlasGrotesk-Medium-Web.woff') format('woff');
}

.ui,
.ui.form,
.ui.form input[type='text'],
.ui.button {
  font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
}

.footer {
  width: 100%;
  height: 0;
}

.full-wide {
  width: 100% !important;
}

.small {
  font-size: 0.9em;
  &.too {
    font-size: 0.8em;
  }
}

.normal {
  font-weight: 100 !important;
}

sup.small {
  font-size: 0.8em;
}

.grey {
  color: gray;

  a {
    color: grey !important;
  }

  &.light {
    color: #d2d2d2;
  }
}

.black {
  color: black !important;
}

.hand:hover {
  cursor: pointer !important;
}

p.compact {
  margin: 0 0 4px 0;
  padding: 0;
  line-height: 14px;
}

.dotted {
  border-bottom: 1px dotted;
}

a.right-align {
  float: right;
}

.pointer {
  cursor: pointer !important;
}

br {
  display: block;
  width: 100%;
  height: 10px;
  content: ' ';
}

.pace .pace-progress {
  height: 3px;
}

.ui.form.edit-block.transparent {
  background-color: transparent;
}

.ui.button.button-preferred {
  padding: 0.6em 1em;
  margin: 0 0 0.4em 0;
}

.right-stick {
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.left-stick {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.top-stick {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.strong {
  font-weight: bold;
}

.ui.icon.input {
  width: 100%;
}

.cancel-button {
  opacity: 0.7;
}

.error-text {
  color: #e03997;
  font-size: 11px;
  font-weight: 600;
  padding-bottom: 10px !important;
  position: absolute !important;
  top: -25px;
}

.width-170 {
  width: 170px;
}

.overflow-hidden {
  overflow: hidden;
}

.ui.action.input {
  width: 100%;
}

.db {
  border: 1px solid red;
}

.link-as-block {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.cdata-id {
  color: #999;
}

.clear {
  clear: both;
}

.red {
  color: $selected-red;
}

.fade {
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.monotype {
  font-family: 'Courier New', sans-serif;
}

.or.mini {
  font-size: 10px !important;
}

.or.mini + .button {
  margin-left: 0 !important;
}

.full-width {
  width: 100% !important;
}

.no-background {
  background: none !important;
}

.ui.icon.external.square {
  font-size: 1.2em;
  cursor: pointer;
  &:hover {
    color: lightskyblue;
  }
}

.tt {
  font-family: 'Lucida Console', monospace;

  &.right {
    text-align: right !important;
  }

  &.center {
    text-align: center !important;
  }
}

.not-matter {
  opacity: 0.2 !important;
}
