.infinity-loader {
  .loading-more,
  .loading-previous {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    font-size: 20px;
    opacity: 0.7;
    background-color: white;
    height: 40px;
    margin: 0;
  }

  .loading-previous {
    position: absolute;
    top: 30px;
    left: 200px;
  }

  .loading-more {
    position: relative;
    margin-top: 20px;
  }
}
