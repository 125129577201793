.preferred-switcher {
  float: right;
  width: 90px;
  text-align: right;
  font-size: 11px;
  color: gray;

  a {
    color: gray;

    .icon {
      font-size: 12px;
    }

    &:hover {
      color: #2185D0;
    }
  }
}