.remove-button {
  width: 30px;
  float: right;
  text-align: right;
  display: none;
  color: gray;
  font-size: 11px;

  a {
    color: gray;
    &:hover {
      color: #2185D0;
    }
  }
}

.ui.grid > .edit-blocks-top-right-controls,
.edit-blocks-top-right-controls {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  min-width: 200px;
  height: 24px;
  color: gray;
  font-size: 11px;
  z-index: 1;

  .remove-button {
    width: 30px;
    float: right;
    text-align: right;
    display: none;
    color: gray;
    font-size: 11px;
  }

  .toggle-preferred {

  }

  .edit {
    float: right;
    width: 28px;
    text-align: right;
    font-size: 11px;
    color: gray;

    a {
      color: gray;

      .icon {
        font-size: 12px;
      }

      &:hover {
        color: #2185D0;
      }
    }
  }

  .create {
    float: right;
    width: 50px;
    text-align: right;
    font-size: 11px;
    color: gray;

    a {
      color: gray;

      .icon {
        font-size: 12px;
      }

      &:hover {
        color: #2185D0;
      }
    }
  }

  a {
    color: gray;
    font-size: 11px;

    .icon {
      font-size: 12px;
    }

    &:hover {
      color: #2185D0;
    }
  }

  a:hover {
    .icon {
      color: #4183C4;
    }
  }
}

.preferred-switcher {
  display: none;
}

.preferred-switcher.active {
  display: block;
  margin-right: 30px;
}

.edit-blocks-top-right-controls {
  .edit,
  .create {
    display: none;
  }
}

.edit + .preferred-switcher.active {
  margin-right: 58px;
}

.hoverable {
  position: relative;
  &:hover {
    background-color: aliceblue !important;
    .edit-blocks-top-right-controls {
      & > .remove-button {
        display: block;
      }
      & > .preferred-switcher {
        display: block;
        margin-right: 0;
      }
      & > .edit,
      & > .create {
        display: block;
      }
    }
  }
  &.edit {
    opacity: 0.4;
    background-color: rgba(255, 224, 39, 0.05) !important;
    .edit-blocks-top-right-controls {
      & > .remove-button,
      & > .preferred-switcher,
      & > .create,
      & > .edit {
        display: none;
      }
    }
  }
}

