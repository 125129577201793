.unbreakable-image {
  background-color: white;
  width: 100%;
  height: 100%;

  .viewer-controls {
    position: absolute;
    bottom: 0;
    right: 42%;
    width: 31px;
    z-index: 10;
    background: black;
    border-radius: 16px;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    .icon {
      margin: 6px 4px;
      font-size: 20px;
      color: white;
      font-weight: normal;
    }
  }
}
