.collectrium-estimate {
  .chart rect {
    fill: #08519c;
    stroke: #08306b
  }

  .chart text {
    fill: #000;
  }

  .bar {
    fill: #9ecae1
  }

  .line {
    fill: none;
  }

  .left {
    float: left;
    height: 330px;
    margin-bottom: 10px;
    width: 400px;
  }
  .area {
    fill-opacity: 0.3;
    clip-path: url(#clip)
  }

  .number {
    float: right;
    text-align: right;
    margin: 10px 10px 0;
    font-size: 15px;
    width: 100px
  }

  #value-number {
    float: right;
    font-weight: bold;
  }

  .title {
    margin: 10px 0 0 10px;
    font-size: 15px;
    width: 250px
  }

  .s.area {
    fill-opacity: 0.3;
    clip-path: url(#sclip)
  }
  .p.line {
    clip-path: url(#pclip)
  }
  .p.area {
    fill-opacity: 0.3;
    clip-path: url(#pclip)
  }

  .axis path, .axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges
  }

  .brush .extent {
    stroke: #4682b4;
    shape-rendering: crispEdges
  }
  @media all and (max-width: 1220px) {
    .left, .right {
      float: none;
      margin-bottom: 5px
    }
  }

  .title {
    font-size: 14px;
  }
}