.account-top {
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 15px;
  z-index: 4;
  font-size: 12px;
  text-align: center;
  padding: 4px;
  cursor: pointer;
  color: #85B7D9;
  display: none;

  &:hover {
    color: black;
  }

  &.fixed {
    position: fixed;
  }

  &.shown {
    display: block;
  }
}