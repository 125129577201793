.context-modal {
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: auto;

  .ui.modal {
    position: relative;
    margin: 100px auto 200px auto !important;
    top: auto;
    left: auto;
    i.icon.close {
      color: black;
    }
  }

  .ui.tiny.modal {
    width: 400px;
    margin: 0 0 0 -200px;
  }

  .ui.small.modal {
    width: 500px;
    margin: 0 0 0 -250px;
  }

  .ui.large.modal {
    width: 1150px;
    margin: 0 0 0 -675px;
  }
}

.ui.modal {
  & > .header,
  & > .header:not(.ui) {
    color: lightgrey;
    padding: 10px 0 0 10px;
    font-size: 16px;
    border-bottom: none;
  }

  & > .content {
    padding: 20px;

    & > .ui.grid {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  & > .actions {
    border-top: none;
    padding-top: 0;
    background-color: inherit;
    text-align: left;
    padding-left: 10px;
  }
}

.flash-message {
  box-shadow: 0 0 15px !important;
}

.blur {
  -webkit-filter: blur(12px);
  -moz-filter: blur(12px);
  -o-filter: blur(12px);
  -ms-filter: blur(12px);
  filter: blur(12px);
}

.no-blur {
  -webkit-filter: none;
  -moz-filter: none;
  -o-filter: none;
  -ms-filter: none;
  filter: none;
}

body {
  &.modal {
    overflow: hidden;
    .application-body {
      @extend .blur;
    }
  }
}

.ui.modal > .close {
  top: -2px !important;
  right: -3px !important;
}