.add-field {
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 250px;

  button {
    i {
      margin-left: 3px !important;
      margin-right: -5px !important;
    }
  }

  .add-field-dropdown {
    position: absolute;
    display: none;
    background-color: white;
    box-shadow: 1px 1px 2px grey;
    max-height: 200px;
    overflow-y: auto;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 250px;

      li {
        width: 100%;
        padding: 5px;

        &:hover {
          background-color: #f2f2f2;
          cursor: pointer;
        }
      }
    }
  }

  &.active .add-field-dropdown {
    display: block;
  }

  button {
    i.left {
      margin-right: 0 !important;
      font-size: 10px;
    }
    i.right {
      margin-left: 10px;
      font-size: 10px;
    }
  }
}

.show-page-header.small .add-field {
  top: 7px;
  left: 115px;
}

