.ui.form {
  .dates-edit-fields {
    .bc-label,
    input[type="checkbox"].bc-label {
      width: 35px;
      float: right;
      input {
        vertical-align: middle;
      }
    }

    label {
      min-height: 18px;
    }

    .field.n-dash {
      label {
        width: 100%;
      }
      label::after {
        content: "‒";
        position: absolute;
        bottom: 24px;
        right: -4px;
      }
    }
  }
}