.custom-estimate {
  padding: 0 10px 0 0;
  position: relative;
  overflow: hidden;
  height: 400px;

  > .title {
    width: 50%;
    float: left;
    display: block;
  }

  > .estimate {
    width: 50%;
    float: right;
    display: block;
    text-align: right;
    font-weight: bold;
  }

  .line {
    width: 4px;
    height: 100%;
    position: absolute;
    left: 54px;
    top: 50px;
    background-color: #e2e2e2;
  }

  .bottom-hider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
  }

  ul {
    height: 320px;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    top: 50px;
    left: 10px;
    width: 90%;

    li {
      display: block;
      width: 100%;
      height: 60px;
      position: relative;

      .year {
        margin-top: 6px;
        display: block;
        width: 70px;
        float: left;
        color: lightgray;
        font-size: 12px;
      }

      .point {
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: grey;
        box-shadow: 0 0 2px 2px white;
        left: 42px;
        top: 12px;
      }

      .estimate {
        margin-top: 6px;
        display: block;
        width: 140px;
        font-size: 13px;
        font-weight: bold;
        float: right;
        text-align: right;

        span {
          font-weight: normal;
        }
      }

      .artworks {
        position: relative;
        width: 80px;
        height: 40px;
        float: left;

        img {
          display: block;
          position: absolute;
          width: 50px;
          box-shadow: 0 0 1px 1px white;

          &.image-1 {
            top: 8px;
            left: 8px;
          }

          &.image-2 {
            top: 16px;
            left: 16px;
          }
        }
      }

    }
  }
}