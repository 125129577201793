.organization-item,
.person-item,
.entity-item,
.lot-item,
.sale-item {
  z-index: 0;
  position: relative;
  min-height: 100%;

  &--info {
    float: left;
    width: 65%;
    padding-left: 140px;
    padding-top: 30px;

    .section-field {
      position: relative;
      width: 100%;
      overflow: hidden;

      &.disabled {
        .title,
        .values,
        .value {
          opacity: 0.3;
        }
      }

      &.empty {
        display: none;
      }

      &.last {
        border-top: 1px solid #dcdcdc;
      }

      &.opened-review {
        background-color: #fff4f6;
      }

      &:hover {
        transition: background-color .2s linear;
        background-color: #f6f6f6;
        .row-actions {
          display: block;
        }
        &.opened-review {
          background-color: #ffdee7;
        }
      }

      & > .title {
        border-top: 1px solid #dcdcdc;
        padding: 8px;
        float: left;
        width: 200px;
        font-weight: 600;
        min-height: 35px;
        clear: both;
      }

      & > .values {
        width: calc(100% - 200px);
        float: right;
        border-top: 1px solid #dcdcdc;

        .secondary {
          color: #999;
        }

        .preferred,
        .value {
          padding: 8px 30px 8px 4px;
          min-height: 35px;

          &.image {
            min-height: 65px;
          }
        }

        .preferred:not(:nth-child(1)),
        .secondary:not(:nth-child(1)),
        .value:not(:nth-child(1)) {
          border-top: 1px solid #dcdcdc;
        }

        span.strong {
          font-weight: normal;
        }

        button {
          vertical-align: baseline;
          margin-left: 20px;
        }

        .ui.icon.hand {
          float: right;
          position: relative;
          right: -25px;

          &:hover {
            color: black;
          }
        }
      }

      .row-actions {
        display: none;
        position: absolute;
        width: 70px;
        height: 50px;
        right: 5px;
        top: 5px;

        & > div {
          text-align: right;
          position: absolute;
          width: 70px;

          i {
            margin-left: 10px;
            font-size: 1.2em;
            cursor: pointer;
            opacity: 0.5;
            float: right;

            &:hover {
              opacity: 1;
            }

            &.ban {
              cursor: default;
              margin-left: 8px;
              margin-top: 5px;
            }
          }
        }
      }

      &.custom {
        border-top: 0;

        &.actions {
          .preferred {
            padding-right: 0;
          }
        }
      }

      &.extension {
        background-color: #f0fcff;

        &:hover {
          background-color: #e2f9ff;
        }
      }

      &.creators {
        a {
          margin-left: 5px;
        }
      }
    }

    .ui.top.attached.tabular.menu {
      position: relative;
      overflow-x: auto;
      overflow-y: hidden;
      font-size: 12px;
      border-bottom: 0;
      min-height: 0;

      .item {
        position: relative;
        padding: 2px 16px;
        height: 24px;

        a {
          position: absolute;

          &.top-right-link {
            display: block;
            position: absolute;
            z-index: 2;
            right: -5px;
            top: 0;
          }
        }

        &.add-new {
          width: 140px;
        }

        &.active {
          background-color: #f2f2f2;
        }
      }

      button {
        position: absolute;
        top: -20px;
        right: 0;
      }
    }
  }

  &--tabs {
    float: left;
    width: 65%;
    padding-left: 140px;
    padding-top: 20px;
    margin-bottom: -30px;
    position: relative;
    z-index: 1;
  }

  &--history-lots {
    position: relative;
    width: 340px;
    float: right;
    margin-top: -100px;
    padding-top: 30px;
    padding-bottom: 100px;
    transition: all .2s linear;

    &.fixed {
      padding-top: 150px;
    }

    & > .border {
      position: absolute;
      top: 20px;
      right: 24px;
      box-sizing: border-box;
      width: 1px;
      height: calc(100% - 170px);
      border: 1px dashed #DADADA;
    }

    .item {
      position: relative;
      background-color: white;
      margin-left: 24px;
      width: 270px;
      box-shadow: 0 2px 1px 0 #CCCCCC;
      padding: 13px 10px;
      margin-bottom: 40px;

      .date-label {
        display: block;
        color: grey;
        position: absolute;
        top: -20px;
        right: 0;
      }

      & > .fa {
        display: none;
      }

      & > a {
        display: block;
      }

      &.active {
        width: 294px;
        margin-left: 0;
        padding-left: 30px;
        padding-right: 20px;

        & > .border {
          width: 5px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: $selected-red;
        }

        & > .fa {
          display: block;
          top: calc(50% - 10px);
          border-radius: 10px;
          position: absolute;
          right: -30px;
          color: #c9c9c9;
          font-size: 20px;
        }

        & > a {
          display: none;
        }

        .image {
          left: 30px;
        }

        .info {
          padding-left: 0;
        }
      }

      p {
        margin: 0;
        font-size: 13px;

        &.header {
          font-weight: bold;
          margin-bottom: 10px;
        }
      }

      .prices {
        margin-left: 70px;
        height: 70px;
        p {
          text-align: left;
        }
      }

      .info {
        width: 100%;
        padding-left: 10px;
        p {
          text-align: left;
        }
      }

      & > a {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }

      &.with-image {
        p.title {
          margin-left: 60px;
        }
      }

      .ui.bottom.right.attached {
        padding: 3px 6px;
        font-size: 11px;
        font-weight: 400;
      }
    }

    > .title {
      font-weight: bold;
      padding: 20px 30px 30px 30px;
      top: 0;
      z-index: 1;
      background-color: inherit;

      sup {
        cursor: pointer;
      }
    }

    .price {
      font-weight: bold;
    }

    .image {
      position: relative;
      float: left;

      top: -5px;
      left: -5px;

      width: 50px;
      height: 50px;

      text-align: center;

      img {
        max-height: 100%;
        max-width: 100%;
        margin: auto;
      }
    }
  }

  &.subject {
    position: initial;

    .entity-item--history-lots {
      position: absolute;
      right: 0;
      top: 385px;
    }

    .entity-item--info {
      margin-top: 20px;
      .section-field:first-child {
        border-top: 2px solid grey;
      }
    }
  }
}

.in-place-editor {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  overflow-y: auto;
  top: 0;
  left: 0;
  padding: 200px 20px;

  .ui.grid.form {
    background-color: white;
    box-shadow: 1px 1px 4px #2b2b2b;
    margin-left: calc(50% - 400px);
    padding: 10px 10px 40px 10px;
    width: 800px;

    .head-actions {
      font-weight: bold;
      font-size: 13px;
      padding-left: 9px;

      a {
        font-weight: normal;
        margin-left: 2em;
      }

      .close {
        position: absolute;
        top: 0;
        right: -2px;
        display: block;
        color: black;
        font-size: 1.2em;
      }
    }

    &.edit-mode {
      .inline-edit-row-cell-actions {
        display: none;
      }

      .edit-block {
        display: none;

        &.edit-mode {
          display: block;
        }
      }
    }
  }

  table.ui.table {
    td {
      padding: 2px;

      &.monotype {
        font-weight: bold;
        font-size: 13px;
        text-align: right;
      }
    }

    &.currencies {
      margin-top: 23px;
    }
  }
}


.thumb-image {
  display: inline-block;
  float: left;
  margin: 0 10px 10px 0;
  width: 50px;
  height: 50px;
  position: relative;
  line-height: 50px;
  box-shadow: 0 0 2px 1px lightgray;
  text-align: center;

  &.pref {
    box-shadow: 0 0 2px 1px cornflowerblue;
  }

  img {
    position: relative;
    top: -1px;
    max-width: 46px;
    max-height: 46px;
    margin: 0 auto;
    vertical-align: middle;
    line-height: 10px;
  }
}
