.subject-default-list {
  position: absolute;
  box-shadow: 1px 1px 3px;
  background-color: white;
  z-index: 20001;
  display: none;
  padding: 0;
  margin: 0;
  width: 100%;
  min-width: 250px;
  border-radius: 3px;

  &.short {
    min-width: 100%;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: visible;

    li {
      list-style: none;
      position: relative;
      padding: 0 !important;

      a {
        padding: 6px;
        display: block;
        width: 100%;
        height: 100%;

        &:hover {
          background-color: #eeeeee;
        }

        span.small {
          color: grey;
          font-size: 0.9em;
        }
      }

      &.item-for-select {
        border-top: 1px solid lightgrey;
      }

      &.item-for-select:focus {
        background-color: lightgrey;
        outline: none;
      }

      &:first-child {
        border: 0;
      }

      .ui.bottom.right.attached.label {
        display: none;
      }
    }
  }

  .bottom {
    padding: 16px 6px 6px 6px;
    text-align: center;
  }

  &.type ul > li > .ui.bottom.right.attached.label {
    background-color: #eeeeee;
    font-weight: normal;
    color: black;
    padding: 4px;
    display: block;
    opacity: 0.5;
  }

  &.active {
    display: block;
  }
}
