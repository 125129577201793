.application-body > .ui.grid.edit-view-mode:first-child {
  margin-top: 10px;
}

.ui.grid.edit-view-mode {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, .1);

  & > .row {
    margin: 0;
    padding: 0;
  }

  .column.open-row-cell,
  .column.edit-row-cell {
    min-height: 30px;
    padding-top: 5px;
    position: relative;

    &.editable > a {
      display: none;
      position: absolute;
      top: -3px;
      left: -3px;
      width: 100%;
      height: calc(100% + 6px);
      z-index: 1;
      text-align: right;

      i.ui.icon {
        margin-top: 7px;
      }
    }

    label {
      float: left;
      font-weight: 600;
      font-size: 12px;
    }

    span {
      display: block;
    }

    @for $i from 1 through 50 {
      $value: ($i - 1) * 10;
      label.size-#{$value} {
        width: 1px * $value - 10px;
      }
      label.size-#{$value} + span {
        margin-left: 1px * $value;
      }
    }

    @media (max-width: 1050px) {
      padding-bottom: 10px;
      label {
        color: darkgray;
      }
      label + span {
        width: 100%;
        float: left;
        margin-left: 10px !important;
      }
    }

    &.editable:hover {
      background-color: white;
      a {
        display: block;
      }
    }

  }

  & > .edit-block {
    display: none;
    position: absolute;
    background-color: white;
    min-height: calc(100% + 3px);
    width: 100%;
    top: 0;
    padding: 10px;
    box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, 0.12);
    z-index: 1;
  }

  &.edit .edit-block {
    display: flex;
  }

  .button {
    margin-bottom: 10px;
    min-width: 90px;
  }

  .flags-checkboxes > .field {
    width: 32%;
    float: left;
    clear: none;

    @media (max-width: 1200px) {
      width: 45%;
    }

    @media (max-width: 1050px) {
      width: 100%;
    }
  }
}