.entity-block-edit {

  & > .edit-row {
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;

    a.edit-action {
      @extend .link-as-block;
      display: none;

      i.icon {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }

    &:hover {
      background-color: white;

      a.edit-action {
        display: block;
      }
    }

    & > .ui.grid.form {
      position: absolute;
      background-color: #f5f5f5;
      top: 0;
      width: 100%;
      box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.15);
      min-height: 100%;
      padding: 10px;
      z-index: 2;

      .head-actions {
        font-weight: bold;
        font-size: 13px;

        a {
          font-weight: normal;
          margin-left: 2em;
        }
      }
    }

    &.edit > .ui.grid.form {
      display: flex;
    }

    .edit-row-left-title {
      font-size: 12px;
      font-weight: 600;
    }

    .edit-row-right-row {
      padding-bottom: 0;
    }

    .edit-row-grid {
      padding-bottom: 20px;
    }
  }

  &.blur .edit-row {
    opacity: 0.2;
    a.edit-action {
      display: none;
    }
    &.edit {
      opacity: 1;
    }
  }

  .title {
    width: 100%;
    padding: 0 40px 0 0;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
}