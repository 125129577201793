.menu-context {
  top: 55px;
  z-index: 9;

  position: fixed;
  width: 100%;
  height: 10px;
  border-color: red;
  max-width: 1418px;

  .dropdown-menu {
    display: none;
    position: absolute;
    right: 10px;
    background-color: white;
    box-shadow: 4px 4px 0 0 rgba(0, 0, 0, .1);

    .action-groups-wrapper {
      display: flex;
      flex: 1 1 auto;

      .actions-group-inner-wrap {
        flex-wrap: wrap;
        min-width: 210px;
        display: flex;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;

        h2 {
          font-size: 14px;
          text-transform: uppercase;
        }

        .actions-group {
          padding: 25px 10px;
          width: 210px;

          .actions-list {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              width: 100%;
              padding: 5px;

              a {
                color: black;
              }

              i.icon {
                width: 30px;
                float: left;
                margin: 0;
              }

              &:hover a {
                color: #990000;
              }
            }
          }
        }
      }
    }
  }

  &.opened {
    .dropdown-menu {
      display: block;
    }
  }

  .button.dropdown-toggle {
    position: absolute;
    top: -25px;
    right: 15px;
    width: 50px;
    height: 50px;
    min-width: 50px;
    border-radius: 50%;
    background-color: #900;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .3);
    cursor: pointer;
    color: transparent !important;
    transition: all .2s;
    border: none;
    z-index: 1000;

    i.actions-button-icon {
      background: url(/images/col-sprite@2x.png) -166px -1165px;
      background-size: 450px 1450px;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(0);
      transition: .4s;
      display: block;
      width: 18px;
      height: 22px;
    }
  }

  &.opened .button.dropdown-toggle {
    transform: rotate(180deg);
  }

  &.opened .button.dropdown-toggle,
  .button.dropdown-toggle:hover {
    background-color: #AF1804;
    width: 54px;
    height: 54px;
    top: -27px;
    right: 13px;
  }
}